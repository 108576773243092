
<template>
<form autocomplete="off" class="">
    <div class="row gx-lg-4 g-3">
        <div class="col-lg-8">
            <label class="form-label">Created between</label>
            <flat-pickr
                v-model="data.created_at"
                :config="rangeConfig"
                placeholder="Select date"
                class="form-control flatpickr-input"
            ></flat-pickr>
        </div>
        <div class="col-sm-6 col-lg-4">
            <label>Status</label>
            <select class="form-select" v-model="data.status"
                name="input-status" placeholder="--select status--">
                <option disabled value="" key="-1">-- select status--</option>
                <option value="pending"> Pending</option>
                <option value="present"> Present</option>
                <option value="absent"> Absent</option>
            </select>
        </div>
        <div class="col-sm-6 col-lg-4">
            <div class="">
            <label>Case Court </label>
            <multiselect 
            mode="single"
            searchable
            valueProp="id"
            trackBy="name" label="name"
            :options="courts" 
            v-model="data.court_id" placeholder="--select court--"></multiselect>
            </div>
        </div>  
        <div class="col-sm-6 col-lg-4">
            <div class="">
            <label>Lawyer </label>
            <multiselect 
                mode="single"
                searchable
                valueProp="id"
                trackBy="name" label="name"
                :options="lawyers" 
                v-model="data.user_id" placeholder="--select  judge--"></multiselect>
            </div>
        </div>
        <div class="col-sm-6 col-lg-4">
            <label class="form-label">
                Checked in between
            </label>
            <flat-pickr
                v-model="data.checkedin_at"
                :config="dateConfig"
                placeholder="Select date"
                class="form-control flatpickr-input"
            ></flat-pickr>
        </div>
    </div>
    <div class="row mt-3 justify-content-end">
        <div class="col-12">
            <div class="mt-3 text-end">
            <b-button variant="primary" class="me-3"
                @click.prevent="getList()"
                type="button"> Get List </b-button>

            <b-button variant="dark" 
                @click.prevent="resetForm()"
                type="button"> Reset </b-button>
            </div>
        </div>
    </div>
</form>
</template>
    
<script>
import Multiselect from '@vueform/multiselect'
import flatPickr from "vue-flatpickr-component";

export default {
    components: {
        Multiselect,
        flatPickr
    },
    data() {
        return {
            data: {
                created_at: "",
                status: "",
                court_id: "",
                user_id: "",
                checkedin_at: ""
            },
            dateConfig:{
                mode: "range",
                dateFormat: "Y-m-d",
                altInput: true,
                showMonths: 1,
                altFormat: "F j, Y",
            },
            rangeConfig: {
                mode: "range",
                dateFormat: "Y-m-d",
                altInput: true,
                showMonths: 2,
                altFormat: "F j, Y",
                // minDate: "today",
                maxDate: new Date().fp_incr(1)
            },
            mirrorData: null
        }
    },
    computed:{
        lawyers(){
            return this.$store.state.userList.lawyers.map((i) => {
                return { id: i.id, name: `${i.first_name} ${i.last_name}`}
            })
        },
        courts(){
            return this.$store.state.court.list
        },
    },
    methods: {
        getList() {
            var payload = Object.fromEntries(
                Object.entries(this.data).filter(([_, v]) => !!v )
            );
            this.$emit('startSearch', payload)
        },
        resetForm() {
            this.data = {...this.mirrorData}
            this.$emit('resetFilters')
        },
    },
    created(){
        this.$store.dispatch('userList/fetchLawyers')
        this.$store.dispatch('court/fetchAllCourts')
    },
    mounted(){
        this.mirrorData = JSON.parse(JSON.stringify(this.data))
    },
}
</script>
